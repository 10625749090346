<script setup>
import { WdsBadge, WdsButtonLink } from '@wds/components';

defineProps({
  tag: {
    type: String,
    required: false,
    default: 'section',
  },
  facilities: {
    type: Array,
    required: true,
  },
});

// EVENTS
const emit = defineEmits(['viewMore']);

// COMPOSABLES
const { getFacilityIcon } = useFacilities();

// METHODS
const toggleFacilitiesPopup = function () {
  emit('viewMore', 'facilities');
};
</script>

<template>
  <component
    :is="tag"
    class="facilities-container"
  >
    <h2 class="facilities-title">
      {{ $t('t_FACILITIES') }}
    </h2>
    <ul class="facilities-list">
      <li
        v-for="facility in facilities"
        :key="facility.name"
        class="facility-item"
      >
        <WdsBadge
          :icon="getFacilityIcon(facility.name)"
          class="facility-badge"
          theme="blue-light"
        />
        <span>{{ $t(`t_${facility.name}`) }}</span>
      </li>
    </ul>
    <WdsButtonLink
      :aria-label="$t('t_VIEWALLFACILITIES')"
      :text="$t('t_VIEWALLFACILITIES')"
      :title="$t('t_VIEWALLFACILITIES')"
      class="facilities-view-all"
      href="#facilities"
      icon-end="chevron-right"
      rel="noopener"
      @click.prevent="toggleFacilitiesPopup"
    />
  </component>
</template>

<style lang="scss" scoped>
.facilities-container {
  display: flex;
  flex-direction: column;

  .facilities-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: $wds-spacing-s;
    width: 100%;

    .facility-item {
      display: inline-flex;
      gap: $wds-spacing-s;
      align-items: center;

      @include body-2-reg;

      .facility-badge {
        padding: wds-rem(12px);
        border-radius: 50%;
      }
    }
  }

  .facilities-view-all {
    cursor: pointer;
    align-self: flex-end;
    margin-top: $wds-spacing-m;
  }

  @include desktop {
    .facilities-list {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
}
</style>
