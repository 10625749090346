<script setup>
import { WdsListItem, WdsPillFloating, WdsPopup } from '@wds/components';
import shareConfig from '~/config/propertyShare';

const { t } = useI18n();
const { isBelowMediumScreen } = useMq();
const sharePopup = ref(false);
const route = useRoute();
const { useUrl } = useUrls();
const runtimeConfig = useRuntimeConfig();
const envData = runtimeConfig?.public;

const urlParams = route?.params || null;

const shareOptions = computed(() => {
  return shareConfig.shareOptions.map((item) => {
    item.label = t(item.label);
    return item;
  });
});

const shareMessage = computed(() => {
  return t('t_LOOKTHISPROPERTY', { PROPERTYNAME: urlParams.propertyName });
});

const shareableUrl = computed(() => {
  return `${useUrl.getBaseURL()}${urlParams.propertyType}/p/${urlParams.propertyId}/${urlParams.propertyName}/`;
});

async function shareButtonClick() {
  // @TODO - tracking

  const shareData = {
    title: 'Hostelworld',
    text: shareMessage.value,
    url: shareableUrl.value,
  };

  if (isBelowMediumScreen.value && navigator.share) {
    try {
      await navigator.share(shareData);
    } catch (err) {
      console.log(err);
    }
  } else {
    sharePopup.value = true;
  }
}

function closeSharePopup() {
  sharePopup.value = false;
}

function copyLink() {
  const url = `${shareableUrl.value}&utm_source=shared_property&utm_medium=clipboard&utm_campaign=${urlParams.propertyId}`;

  nextTick(() => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        // @TODO check later what to do with this as we need new designs for the TOAST
        // $store.commit('toast/show', {
        //   message: $t('t_SUCCESS'),
        //   type: 'success',
        // });
      })
      .catch((error) => {
        console.error('Error copying to clipboard:', error);
      });
  });
}

function sendEmail() {
  const url = `${shareableUrl.value}&utm_source=shared_property&utm_medium=email&utm_campaign=${urlParams.propertyId}`;
  const emailUrl = `mailto:?body=${encodeURIComponent(url)}&subject=${encodeURIComponent(shareMessage.value)}`;

  window.open(emailUrl);
}

function shareWhatsapp() {
  const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    `${shareableUrl.value}&utm_source=shared_property&utm_medium=whatsapp&utm_campaign=${urlParams.propertyId}`,
  )}`;
  window.open(url);
}

function shareMessenger() {
  const appId = envData.FACEBOOK_APP_ID;
  const urlString = `${shareableUrl.value}&utm_source=shared_property&utm_medium=fb_messenger&utm_campaign=${urlParams.propertyId}`;
  const url = isBelowMediumScreen.value
    ? `fb-messenger://share/?link=${encodeURIComponent(urlString)}&app_id=${appId}`
    : `http://www.facebook.com/dialog/send?app_id=${appId}&link=${encodeURIComponent(
      urlString,
    )}&redirect_uri=${encodeURIComponent(urlString)}`;

  window.open(url);
}

function shareMethodTracking(option) {
  const optionText = option === 'mail' ? 'email' : option;
  // @TODO - tracking and remove console log
  console.log(optionText);
}

function handleShareOptionClick(option) {
  shareMethodTracking(option);

  switch (option) {
    case 'clipboard':
      copyLink();
      break;
    case 'mail':
      sendEmail();
      break;
    case 'whatsapp':
      shareWhatsapp();
      break;
    case 'fb_messenger':
      shareMessenger();
      break;
  }
}

// @TODO - tracking on mounted
</script>

<template>
  <div class="property-share">
    <WdsPillFloating
      icon-start="share"
      class="property-share-button"
      aria-label="Share Button"
      @click="shareButtonClick"
    />
    <WdsPopup
      v-if="sharePopup"
      is-open
      :heading="$t('t_SHARETHISHOSTEL')"
      close-button
      :apply-sheet="isBelowMediumScreen"
      @popup-close="closeSharePopup"
    >
      <template #popupContent>
        <div class="share-options">
          <WdsListItem
            v-for="item in shareOptions"
            :key="item.value"
            :label="$t(item.label)"
            :icon="item.icon"
            @item-selected="handleShareOptionClick(item.value)"
          />
        </div>
      </template>
    </WdsPopup>
  </div>
</template>

<style lang="scss" scoped>
.property-share {
  z-index: 100;

  .property-share-button {
    position: absolute;
    right: 0;
    margin-top: -1.5rem;
    margin-right: wds-rem(10px);
    z-index: 1;
  }

  @include tablet {
    .property-share-button {
      margin-right: wds-rem(25px);
    }
  }

  :deep(.modal-container) {
    width: wds-rem(400px);
  }

  .share-options {
    padding-top: $wds-spacing-s;
    padding-bottom: $wds-spacing-l;
  }
}
</style>
