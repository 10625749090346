<script setup>
import { findPropertyTypeByKey } from '~/utils/propertyTypes';

const {
  locale,
  t,
} = useI18n();
const { useUrl } = useUrls();
const { buildSeo } = generateSeo();
const { today } = useDateTime();
const { useRouteManager } = useRoutes();
const {
  isBelowMediumScreen,
  isSmallOrMediumScreen,
} = useMq();
const dateTime = useDateTime();

const route = useRoute();
const { getTopPropertiesByCityId } = useApiCities();
const { getPropertyPageTags } = useApiSeo();
const { getPropertyLocationTranslatedById } = useApiTranslator();

onMounted(() => {
  window.addEventListener('scroll', handleScroll, { passive: true });
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});
// ### STORES
const languageStore = useLanguageStore();
const sessionStore = useSessionStore();
const locationStore = useLocationStore();

// ### VARIABLES INITIALIZATION
const urlParams = route?.params || null;
const scrolled = ref(false);
const propertyDetailsActiveTab = ref(null);
const isPropertyDetailsOpen = ref(false);
const isLocationModalOpen = ref(false);
const isReviewsModalOpen = ref(false);
const urlQuery = route?.query || null;
const locationKey = 'property';
const entryPageName = 'PROPERTY';
const pageInfoData = {
  numberOfProperties: 0,
  numberOfCities: 0,
  canonicalUrl: '',
  ogImage: '',
  avgRating: '',
  numberOfReviews: '',
  numberOfCountries: 0,
};

// ### ROUTE VALIDATION
const propertyTypeObj = useRouteManager.validatePropertyTypeParam(urlParams.propertyType);
const propertyTypeKey = propertyTypeObj?.key;

const propertyData = await useRouteManager.validatePropertyParam(propertyTypeObj, urlParams.propertyId);
const urlData = useRouteManager.getUrlData(
  urlParams.propertyType,
  null,
  null,
  null,
  null,
  urlParams.propertyId,
  urlParams.propertyName,
);
useRouteManager.redirectRoute(urlData, urlQuery);

/*
 * @TODO: Missing route validation
 *
 * @TODO: if countryObj is null or invalid, it should already thrown an error and redirect user to 404/500,
 * if not, do it here
 */

locationStore.setLocation(locationKey, {
  id: propertyData?.id,
  name: `${propertyData?.name}, ${propertyData?.location?.city?.name}, ${propertyData?.location?.country?.name}`,
});

const seoData = await getPropertyPageTags(propertyTypeObj.key, propertyData.id);
const urlTranslations = await getPropertyLocationTranslatedById(propertyData?.id);
const hrefUrls = useUrl.generateHrefLangForProperty(urlTranslations, propertyTypeObj.key, propertyData.id);

const tradReplacements = {
  PROPERTYNAME: propertyData?.name,
  LOCATIONNAME: propertyData?.name,
  CITYNAME: propertyData?.location?.city?.name,
  CITY: propertyData?.location?.city?.name,
  REVIEWSCOUNT: propertyData?.reviews?.totalCount || '',
  COUNTRYNAME: propertyData?.location?.country?.name,
  COUNTRY: propertyData?.location?.country?.name,
  PROPERTYTYPE: t(propertyTypeObj?.transcode_s),
  PROPERTYTYPES: t(propertyTypeObj?.transcode),
  YEAR: today.year,
};
const seoMetaData = buildSeo.getMetaData(seoData, pageInfoData, tradReplacements);

const topPropertiesInCity = await getTopPropertiesByCityId(propertyTypeKey, propertyData?.location?.city?.id);

// More properties in the same city as the current property
const morePropertiesInCity = topPropertiesInCity?.filter((c) => c.id !== propertyData.id)?.slice(0, 6) || [];

// METHODS
function handleScroll() {
  const navSection = document.getElementById('quick-nav-ref');
  scrolled.value = navSection?.getBoundingClientRect().bottom < 0;
}

// ### COMPUTED
const getPropertyFlatFacilites = computed(() => {
  return propertyData?.facilities?.flatMap((item) => [...item.facilities]) || [];
});

const getPropertyHeadingData = computed(() => {
  // PROPERTY TYPE
  const propertyTypeInfo = findPropertyTypeByKey(propertyData.type);

  // HOSCARS WINNER
  const isHoscarWinnerForCurrentYear = propertyData?.hoscars?.length && propertyData.hoscars[0].year === today.year;
  const hoscarWinnerForCurrentYearTitle = isHoscarWinnerForCurrentYear
    ? `${today.year} HOSCARS ${t('t_WINNER').toUpperCase()}`
    : false;

  return {
    propertyId: propertyData.id,
    propertyType: t(propertyTypeInfo.transcode_s),
    propertyName: propertyData?.name,
    propertyLocation: propertyData?.location,
    propertyRating: {
      averageRating: propertyData?.reviews?.totalAverageRating,
      numberOfReviews: propertyData?.reviews?.totalCount,
    },
    propertySustainability: propertyData?.sustainability,
    propertyBadges: {
      awardWinner: hoscarWinnerForCurrentYearTitle,
      recommendedByHw: propertyData?.recommendedByHw ? t('t_RECOMMENDED') : false,
      freeWifi: propertyData?.badges?.find((b) => b.id === '90'),
      breakFastIncluded: propertyData?.badges?.find((b) => b.id === '1'),
    },
    propertyTotalFacilities: getPropertyFlatFacilites.value?.length || 0,
  };
});

const getPropertyFacilitiesSnippetData = computed(() => {
  return { facilities: getPropertyFlatFacilites.value.slice(0, 8) || [] };
});

const propertyHasFacilities = computed(() => {
  return toValue(getPropertyFacilitiesSnippetData)?.facilities?.length;
});

const getPropertyHouseRulesData = computed(() => {
  return {
    checkInTime: propertyData?.policies?.checkIn,
    checkOutTime: propertyData?.policies?.checkOut,
    additionalRules: propertyData?.policies?.additional,
    thingsToNote: propertyData?.policies?.thingsToNote,
    cancellationPolicy: propertyData?.policies?.cancellationPolicy,
  };
});

const getMorePropertiesInCityListing = computed(() => morePropertiesInCity || []);

const hasRatingAndReviews = computed(() => {
  return propertyData?.reviews?.totalAverageRating > 0 && propertyData?.reviews?.latest?.length;
});

const getPropertyRatingAndReviewsData = computed(() => {
  return {
    propertyId: propertyData.id,
    propertyRating: {
      averageRating: propertyData?.reviews?.totalAverageRating,
      numberOfReviews: propertyData?.reviews?.totalCount,
      breakdown: propertyData?.reviews?.averageRatings,
    },
    propertyLatestReviews: propertyData?.reviews?.latest?.map((review) => {
      const reviewScore = review.rating || 0;
      const reviewDescription = [t(`t_${review.guestGender.toUpperCase().replace(/ /g, '')}`) || ''];
      reviewDescription.push(review.guestAgeGroup || '');
      reviewDescription.push(review.guestCountry || '');
      const reviewDate = review.stayed
        ? dateTime.formatToHumanReadableShortDate(review.stayed, languageStore.getCurrentLanguage.iso, 'month|year')
        : '';

      return {
        date: `${t('t_STAYEDINDATE', { DATE: reviewDate })}`,
        text: review.review,
        rating: Number(reviewScore.toFixed(1)),
        author: {
          userName: review.guestFirstname,
          description: reviewDescription.join(', '),
          countryCode: review.guestCountryCode,
          countryName: review.guestCountry,
          ageRange: review.guestAgeGroup,
          groupTypeCode: review.guestGender,
          flagURL: `https://a.hwstatic.com/hw/flags/${review.guestCountryCode}.svg`,
          color: 'pink',
        },
      };
    }),
  };
});

// METHODS
const propertyDetailsTabsData = computed(() => {
  return {
    about: { description: propertyData?.descriptions?.main || '' },
    facilities: propertyData?.facilities,
    houserules: toValue(getPropertyHouseRulesData),
    hoscars: propertyData?.hoscars,
    sustainability: propertyData?.sustainability,
  };
});

const togglePropertySectionPopup = function (tab = null) {
  switch (tab) {
    case 'location':
      isLocationModalOpen.value = !isLocationModalOpen.value;
      break;
    case 'reviews':
      isReviewsModalOpen.value = !isReviewsModalOpen.value;
      break;
    case null:
      isPropertyDetailsOpen.value = false;
      break;
    default:
      propertyDetailsActiveTab.value = tab;
      isPropertyDetailsOpen.value = true;
      break;
  }
};

useHead(() => ({
  htmlAttrs: { lang: locale?.value },
  link: [
    {
      rel: 'canonical',
      href: seoMetaData?.canonicalUrl,
    },
  ].concat(hrefUrls),
}));

useSeoMeta({
  title: () => seoMetaData?.title,
  ogTitle: () => seoMetaData?.ogTitle,
  description: () => seoMetaData?.description,
  ogDescription: () => seoMetaData?.ogDescription,
  ogUrl: () => seoMetaData?.canonicalUrl,
  ogImage: () => seoMetaData?.ogImage,
  ogImageAlt: () => seoMetaData?.ogDescription,
  ogType: () => 'website',
  keywords: () => seoMetaData?.keywords,
  robots: () => 'index, follow, maxImagePreview:large',
  twitterCard: () => 'summary_large_image',
  twitterTitle: () => seoMetaData?.ogTitle,
  twitterDescription: () => seoMetaData?.ogDescription,
  twitterImage: () => seoMetaData?.ogImage,
  twitterImageAlt: () => seoMetaData?.ogDescription,
});

// ### TRACKING
sessionStore.setEntryPoint(entryPageName);
</script>

<script>
/* eslint-disable */
import { ca } from '~/lib/channelAttribution.js';

const entryPageNameJs = 'PROPERTY';

if (import.meta.client) {
  ca?.setChannelAttribution(null, entryPageNameJs);
}
/* eslint-enable */
</script>

<template>
  <CommonHwHeader
    :single-bar="true"
    :sticky="false"
    class="property-header"
  />

  <PropertyHwImageGallery
    :image-list="propertyData?.images"
    :property-name="propertyData?.name"
    :video-list="propertyData?.videos"
    class="hw-wrapper ms-section image-gallery-section"
  />

  <PropertyHwHeading
    id="quick-nav-ref"
    class="hw-wrapper ms-section heading-section"
    v-bind="getPropertyHeadingData"
    @view-more="togglePropertySectionPopup"
  />

  <PropertyHwQuickNav
    v-if="!toValue(isBelowMediumScreen)"
    :class="{ sticky: scrolled }"
    class="ms-section quick-nav-section"
    @view-more="togglePropertySectionPopup"
  />

  <PropertyHwAbout
    :description="propertyData?.descriptions?.main"
    class="hw-wrapper ms-section about-section"
    @view-more="togglePropertySectionPopup"
  />

  <PropertyHwHouseRules
    class="hw-wrapper ms-section house-rules-section"
    v-bind="getPropertyHouseRulesData"
    @view-more="togglePropertySectionPopup"
  />

  <PropertyHwFacilities
    v-if="propertyHasFacilities"
    class="hw-wrapper ms-section facilities-section"
    v-bind="getPropertyFacilitiesSnippetData"
    @view-more="togglePropertySectionPopup"
  />

  <PropertyHwReviews
    v-if="hasRatingAndReviews"
    class="ms-section rating-and-reviews-section"
    v-bind="getPropertyRatingAndReviewsData"
    @display-modal="togglePropertySectionPopup"
  />

  <PropertyHwMap
    :property-location="propertyData?.location"
    class="hw-wrapper ms-section"
    @display-modal="togglePropertySectionPopup"
  />

  <PropertyHwHostelworldSays
    v-if="propertyData?.hostelworldSays"
    :description="propertyData?.hostelworldSays"
    class="hw-wrapper ms-section"
  />
  <PropertyHwMorePropertiesInCity
    :city-data="propertyData?.location"
    :property-list="getMorePropertiesInCityListing"
    :property-type-obj="propertyTypeObj"
  />

  <LazyPropertyHwReviewsModal
    v-if="isReviewsModalOpen"
    :apply-sheet="isSmallOrMediumScreen"
    :property-name="propertyData?.name"
    v-bind="getPropertyRatingAndReviewsData"
    @close-property-reviews="togglePropertySectionPopup"
  />

  <LazyPropertyHwMapLocationModal
    v-if="isLocationModalOpen"
    :apply-sheet="isSmallOrMediumScreen"
    :property-location="propertyData?.location"
    :property-type-obj="propertyTypeObj"
    @close-property-location="togglePropertySectionPopup"
  />

  <LazyPropertyHwDetailsPopup
    v-if="isPropertyDetailsOpen"
    :active-tab="propertyDetailsActiveTab"
    :apply-sheet="isSmallOrMediumScreen"
    :property-type-obj="propertyTypeObj"
    :tabs-data="propertyDetailsTabsData"
    @close-popup="togglePropertySectionPopup"
  />

  <CommonHwLanguagePicker v-show="languageStore.isLanguagePickerOpen" />
</template>

<style lang="scss" scoped>
.property-header {
  margin-bottom: 0;
}

.ms-section {
  margin: 0 auto $wds-spacing-m;
  padding-inline: $wds-spacing-m;

  @include tablet {
    padding-inline: $wds-spacing-l;
  }
}

.image-gallery-section {
  padding-inline: $wds-spacing-m 0;

  @include tablet {
    padding-inline: $wds-spacing-l 0;
  }

  @include desktop {
    padding-inline: $wds-spacing-l;
  }
}

.heading-section {
  @include tablet {
    padding-top: $wds-spacing-l;
  }
}

.quick-nav-section {
  background-color: $wds-color-ink-lightest;
  display: block;

  &.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $wds-z_index-dropdown;
  }
}

.about-section,
.house-rules-section,
.facilities-section,
.rating-and-reviews-section {
  :deep(h2) {
    @include title-5-bld;

    margin-bottom: $wds-spacing-s;

    @include tablet-large {
      @include title-3-bld;

      margin-bottom: $wds-spacing-m;
    }
  }

  @include tablet {
    padding-inline: $wds-spacing-l;
  }

  &::after {
    content: ' ';
    display: block;
    height: 1px;
    width: 100%;
    background-color: $wds-color-ink-lighter;
    margin-top: $wds-spacing-l;
  }
}

.rating-and-reviews-section {
  padding-inline: 0;

  &::after {
    display: none;
  }

  @include desktop {
    max-width: wds-rem(1600px);
  }
}

.wrapper {
  .inner-wrapper {
    padding: $wds-spacing-m 0;

    h1 {
      color: $wds-color-ink-darker;
      font-size: $wds-font-size-title-2;
      font-weight: $wds-font-weight-bold;
      line-height: $wds-line-height-title-2;
    }

    p {
      margin: 16px 0;
    }

    :deep(.item-list .contents) {
      grid-template-columns: 1fr 1fr;

      @media (min-width: $screen-medium) {
        grid-template-columns: 1fr 1fr 1fr;
      }

      @media (min-width: $screen-large) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }
  }
}
</style>
