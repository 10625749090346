export const FACILITY_ICONS = {
  '24HOURRECEPTION': 'reception',
  '24HOURSECURITY': 'security',
  'ADAPTORS': 'adaptor',
  'AGERESTRICTION': 'restriction',
  'AIRCONDITIONING': 'air-conditioning',
  'AIRPORTTRANSFERS': 'transfer',
  'ATM': 'atm',
  'BAR': 'bar',
  'BBQ': 'bbq',
  'BICYCLEHIRE': 'bicycle',
  'BICYCLEPARKING': 'bicycle',
  'BOARDGAMES': 'board-games',
  'BOOKEXCHANGE': 'book',
  'BREAKFASTINCLUDED': 'breakfast',
  'BREAKFASTNOTINCLUDED': 'breakfast',
  'CABLETV': 'tv',
  'CAFE': 'breakfast',
  'CARDPHONES': 'phone',
  'CEILINGFAN': 'ceiling-fan',
  'CHILDFRIENDLY': 'children',
  'CHILDRENSPLAYAREA': 'childrens-play',
  'COMMONROOM': 'sofa',
  'COOKER': 'cooker',
  'COTSAVAILABLE': 'cots',
  'CREDITCARDSACCEPTED': 'credit-card',
  'CURFEW': 'clock',
  'CURRENCYEXCHANGE': 'currency-exchange',
  'DIRECTDIALTELEPHONE': 'phone',
  'DISHWASHER': 'dishwasher',
  'DRYER': 'laundry',
  'DVDS': 'dvd',
  'ELEVATOR': 'elevator',
  'EXPRESSCHECKINOUT': 'express-check-in-out',
  'FAXSERVICE': 'phone',
  'FLEXIBLENRR': 'change-date',
  'FREEAIRPORTTRANSFERS': 'transfer',
  'FREECITYMAPS': 'map',
  'FREECITYTOUR': 'city',
  'FREEINTERNETACCESS': 'internet',
  'FREEPARKING': 'parking',
  'FREEWIFI': 'wi-fi',
  'FRIDGEFREEZER': 'fridge',
  'FUSBALL': 'foosball',
  'GAMESROOM': 'games-room',
  'GYM': 'gym',
  'HAIRDRYERS': 'hair-dryer',
  'HAIRDRYERSFORHIRE': 'hair-dryer',
  'HOTSHOWERS': 'shower',
  'HOTTUB': 'hot-tube',
  'HOUSEKEEPING': 'housekeeping',
  'INDOORSWIMMINGPOOL': 'swimming-pool',
  'INTERNETACCESS': 'internet',
  'INTERNETCAF': 'internet',
  'IRONIRONINGBOARD': 'iron',
  'JOBSBOARD': 'jobs-board',
  'KEYCARDACCESS': 'key-card',
  'KITCHEN': 'pan',
  'LATECHECKOUT': 'night',
  'LAUNDRYFACILITIES': 'laundry',
  'LINENINCLUDED': 'linen',
  'LINENNOTINCLUDED': 'linen',
  'LOCKERS': 'safe-deposit-box',
  'LUGGAGESTORAGE': 'luggage',
  'MEALSAVAILABLE': 'meal',
  'MEETINGROOM': 'meeting-room',
  'MICROWAVE': 'microwave',
  'MINISUPERMARKET': 'supermarket',
  'NIGHTCLUB': 'nightclub',
  'NOCURFEW': 'clock',
  'NONSMOKING': 'no-smoke',
  'NOTCHILDFRIENDLY': 'children',
  'OUTDOORSWIMMINGPOOL': 'swimming-pool',
  'OUTDOORTERRACE': 'terrace',
  'PARKING': 'parking',
  'PETFRIENDLY': 'pet',
  'PLAYSTATION': 'gamepad',
  'POOLTABLE': 'pool-table',
  'POSTALSERVICE': 'envelope',
  'READINGLIGHT': 'reading-light',
  'RECEPTIONLIMITEDHOURS': 'reception',
  'RESTAURANT': 'utensils',
  'SAFEDEPOSITBOX': 'safe-deposit-box',
  'SANITISATIONBADGE': 'sanitation-badge',
  'SAUNA': 'sauna',
  'SHUTTLEBUS': 'transfer',
  'STEAMROOM': 'sauna',
  'SWIMMINGPOOL': 'swimming-pool',
  'TAXESINCLUDED': 'money',
  'TAXESNOTINCLUDED': 'money',
  'TEACOFFEEMAKINGFACILITIES': 'breakfast',
  'TOURSTRAVELDESK': 'travel-desk',
  'TOWELSFORHIRE': 'towel',
  'TOWELSINCLUDED': 'towel',
  'TOWELSNOTINCLUDED': 'towel',
  'UTENSILS': 'utensils',
  'VENDINGMACHINES': 'vendingmachines',
  'WAKEUPCALLS': 'wake-up-call',
  'WASHINGMACHINE': 'laundry',
  'WHEELCHAIRACCESSIBLE': 'wheelchair',
  'WIFI': 'wi-fi',
  'WII': 'gamepad',
};
