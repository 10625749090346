<script setup>
import { WdsButtonGhost, WdsIcon } from '@wds/components';

const props = defineProps({
  tag: {
    type: String,
    required: false,
    default: 'section',
  },
  propertyLocation: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['displayModal']);
const propertyAddress = computed(() => {
  const {
    address: {
      line1,
      line2,
    },

  } = props.propertyLocation;
  return [line1, line2].filter((l) => l).join(', ');
});

const roundedDistance = computed(() => {
  return Math.round(props.propertyLocation.cityCenterDistance * 10) / 10;
});

function copyAddress() {
  nextTick(() => {
    navigator.clipboard
      .writeText(`${propertyAddress.value}, ${props.propertyLocation.city.name}`)
      .then(() => {
        console.log('text copied');
        // @TODO check later what to do with this as we need new designs for the TOAST (with butter)
        // $store.commit('toast/show', {
        //   message: $t('t_SUCCESS'),
        //   type: 'success',
        // });
      })
      .catch((error) => {
        console.error('Error copying to clipboard:', error);
      });
  });
}

function handleMapModal() {
  emit('displayModal', 'location');
}
</script>

<template>
  <component
    :is="tag"
    class="location-map-section"
  >
    <div class="location-snippet">
      <div class="location-snippet-container">
        <div class="location-snippet-overlay">
          <h2 class="location-snippet-title">
            {{ $t('t_LOCATION') }}
          </h2>
          <div class="location-snippet-address">
            <WdsIcon asset="location-pin" />
            <span class="location-snippet-link">
              {{ `${propertyAddress}${propertyLocation.city.name && ','}` }}
              {{ `${propertyLocation.city.name}${propertyLocation.country.name && ','}` }}
              {{ propertyLocation.country.name }}
            </span>
            <div
              class="copy-address"
              @click="copyAddress"
            >
              <WdsIcon asset="copy" />
            </div>
          </div>
          <div class="location-snippet-city-center">
            <WdsIcon asset="city" />
            <span class="location-snippet-link">{{ $t('t_FROMCITYCENTER', { DISTANCE: roundedDistance }) }}</span>
          </div>
        </div>
        <img
          class="location-snippet-map"
          src="@/public/map-horizontal.svg"
          alt="map"
        />
        <WdsButtonGhost
          :text="$t('t_VIEWMAP')"
          icon-end="chevron-right"
          @click="handleMapModal"
        />
      </div>
    </div>
  </component>
</template>

<style scoped lang="scss">
.location-snippet {
  &-title {
    @include title-4-bld;
  }

  &-container {
    position: relative;

    @include tablet-large {
      margin-right: 0;
    }
  }

  &-map {
    width: 100%;
    height: wds-rem(260px);
    border-radius: $wds-border-radius-m;
    object-fit: cover;
    transform: scaleX(-1);
  }

  &-overlay {
    position: absolute;
    inset: 0;
    padding: $wds-spacing-xl $wds-spacing-l;
    z-index: 1;
  }

  &-address {
    margin-top: $wds-spacing-s;
    display: flex;
    align-items: center;
    max-width: 600px;

    .copy-address {
      cursor: pointer;
    }
  }

  &-link {
    @include link-2-reg;

    margin: 0 $wds-spacing-xs;
    max-width: wds-rem(212px);

    :deep(.icon-container) {
      fill: $wds-color-ink-light;
    }
  }

  &-city-center {
    display: flex;
    align-items: center;
    margin-top: $wds-spacing-s;
  }

  .btn-content {
    position: absolute;
    margin: auto;
    bottom: $wds-spacing-xl;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    z-index: 1;
  }

  @include tablet-large {
    &-overlay {
      position: static;
      padding: 0;
      margin-bottom: $wds-spacing-l;
    }

    .btn-content {
      transform: translateY(-50%);
    }

    &-map {
      height: wds-rem(160px);
    }
  }

  @include desktop {
    &-overlay {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: $wds-spacing-l;
    }

    .btn-content {
      right: $wds-spacing-xl;
      top: auto;
      left: auto;
      transform: none;
    }
  }
}
</style>
