<script setup>
import { WdsButtonPrimary } from '@wds/components';
import { findPropertyTypeByKey } from '~/utils/propertyTypes';

const props = defineProps({
  cityData: {
    type: Object,
    required: true,
  },
  propertyTypeObj: {
    type: Object,
    required: true,
  },
  propertyList: {
    type: Array,
    required: true,
  },
});

const { useUrl } = useUrls();
const { useImage } = useImages();
const { t } = useI18n();

// ## Methods
function navigateToCityFab() {
  return useUrl.getCityPageUrl({
    propertyTypeSlug: props.propertyTypeObj?.slug,
    urlFriendlyContinent: props.cityData?.continent?.urlFriendlyName,
    urlFriendlyCountry: props.cityData?.country?.urlFriendlyName,
    urlFriendlyCity: props.cityData?.city?.urlFriendlyName,
  });
}

// ## Computed
const hasMorePropertiesInCity = computed(() => props.propertyList?.length);
const propertyTypeTitleCase = computed(() => {
  return props.propertyTypeObj.type.charAt(0).toUpperCase() + props.propertyTypeObj.type.slice(1).toLowerCase();
});

const getMorePropertiesInCity = computed(() => {
  return props.propertyList.map((property) => {
    const propertyImage = property?.image?.small || null;
    const propertyImageUrl = propertyImage ? useImage.getImage(propertyImage) : null;

    const actualPropertyTypeObj = findPropertyTypeByKey(property?.type);

    const locationData = {
      propertyTypeSlug: t(actualPropertyTypeObj.slug),
      urlFriendlyContinent: property?.urlFriendlyContinent,
      urlFriendlyCountry: property?.urlFriendlyCountry,
      urlFriendlyCity: property?.urlFriendlyName,
      urlFriendlyProperty: property?.urlFriendlyName,
      propertyId: property?.id,
    };

    const propSharedMinPrice = property?.sharedMinPrice?.value || 0;
    const propPrivateMinPrice = property?.privateMinPrice?.value || 0;
    const propPriceCurrency
      = property?.sharedMinPrice?.currency || property?.privateMinPrice?.currency || DEFAULT_CURRENCY.code;
    const propPrices = [Number.parseFloat(propSharedMinPrice), Number.parseFloat(propPrivateMinPrice)].filter(
      (price) => price > 0,
    );

    return {
      id: property?.id,
      name: property?.name,
      image: propertyImageUrl,
      rating: {
        score: property?.avgRating,
        showReviews: false,
        showKeyword: false,
      },
      price: {
        value: Math.min(...propPrices),
        currency: propPriceCurrency,
      },
      link: useUrl.getPropertyPageUrl(locationData),
    };
  });
});

const getMorePropertiesInCityTitle = computed(() => {
  return t('t_MOREPROPERTIESINLOCATION', {
    PROPERTYTYPES: t(props.propertyTypeObj.transcode).toLowerCase(),
    CITY: props.cityData?.city?.name,
  });
});
</script>

<template>
  <div
    v-if="hasMorePropertiesInCity"
    class="more-properties-base"
  >
    <h2>{{ getMorePropertiesInCityTitle }}</h2>
    <div class="more-properties-listing">
      <CommonHwDestinationCard
        v-for="property in getMorePropertiesInCity"
        :key="property.id"
        v-bind="property"
      />
    </div>
    <div class="view-all-city-properties-container">
      <WdsButtonPrimary
        :text="
          $t('t_VIEWALLCITYPROPTYPES', {
            CITY: cityData.city.name,
            PROPTYPES: propertyTypeTitleCase,
          })
        "
        :href="navigateToCityFab()"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.more-properties-base {
  display: flex;
  flex-direction: column;
  gap: $wds-spacing-l;
  margin: $wds-spacing-xxxl $wds-spacing-m 0 !important;

  h2 {
    color: $wds-color-ink-dark;

    @include title-4-bld;
  }

  .more-properties-listing {
    display: flex;
    flex-direction: column;
    gap: $wds-spacing-m;
  }

  @include tablet-large {
    .more-properties-listing {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  @include desktop {
    margin: $wds-spacing-xxxl wds-rem(100px) 0 !important;

    .more-properties-listing {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

.view-all-city-properties-container {
  padding: $wds-spacing-m;
}
</style>
